import React, {Component} from 'react';
import {AUTH_TOKEN} from './api-service';

const AuthContext = React.createContext({});

class SystemContext extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem(AUTH_TOKEN),
    };
  }

  isUserLoggedIn = () => {
    return this.state && this.state.token;
    /*
    const {token} = this.state;
    if (token) {
      return true;
    }
    return false;
     */
  };

  signIn = (token) => {
    localStorage.setItem(AUTH_TOKEN, token);
    this.setState(oldState => ({
      ...oldState,
      token: token,
    }));
  };

  signOut = () => {
    localStorage.removeItem(AUTH_TOKEN);
    this.setState(oldState => ({
      ...oldState,
      token: null,
    }));
  };

  set = (key, value) => {
    this.setState(oldState => ({
      ...oldState,
      [key]: value,
    }));
  };

  get = (key, defaultValue) => {
    const x = this.state[key];
    if (x === undefined) {
      return defaultValue;
    }
    return x;
  };

  render() {
    return (
      <AuthContext.Provider
        value={{
          ...this.state,
          isUserLoggedIn: this.isUserLoggedIn,
          signIn: this.signIn,
          signOut: this.signOut,
          set: this.set,
          get: this.get,
        }}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export {AuthContext, SystemContext};
