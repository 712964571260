import React, {useContext} from "react";
import {Link, useNavigate} from "react-router-dom";
import {AuthContext} from "../services/auth-context";

const SideMenu = ({active}) => {
    const {signOut, set, get} = useContext(AuthContext);
    const navigate = useNavigate();

    const logout = () => {
        signOut();
        navigate("/login", { replace: true });
    };

    const hideMenu = () => {
        set('sidebar-class', '');
    };

    const sidebarClass = get('sidebar-class', 'active');

    return  (
        <div id="sidebar" className={sidebarClass}>
            <div className="sidebar-wrapper active">
                <div className="sidebar-header">
                    <div className="d-flex justify-content-between">
                        <div className="logo">
                            <img src={'/favicon.ico'} alt={'logo'}/> Wordle Wallet
                        </div>
                        <div className="toggler">
                            <button className="sidebar-hide d-xl-none d-block btn btn-default" onClick={hideMenu}>
                                <i className="bi bi-x bi-middle"/>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="sidebar-menu">
                    <ul className="menu">
                        <li className="sidebar-title">Menu</li>

                        <li className={"sidebar-item " + (active === 'dashboard' ? 'active' : '')}>
                            <Link to="/" className='sidebar-link'>
                                <i className="bi bi-grid-fill" />
                                <span>Dashboard</span>
                            </Link>
                        </li>

                        <li className={"sidebar-item " + (active === 'wallets' ? 'active' : '')}>
                            <Link to="/wallets?status=true" className='sidebar-link'>
                                <i className="bi bi-wallet-fill" />
                                <span>Wallets</span>
                            </Link>
                        </li>

                        <li className={"sidebar-item " + (active === 'users' ? 'active' : '')}>
                            <Link to="/users" className='sidebar-link'>
                                <i className="bi bi-person-badge-fill"/>
                                <span>Users</span>
                            </Link>
                        </li>

                        <li className={"sidebar-item " + (active === 'games' ? 'active' : '')}>
                            <Link to="/games" className='sidebar-link'>
                                <i className="bi bi-grid-fill" />
                                <span>Games</span>
                            </Link>
                        </li>

                        <li className={"sidebar-item " + (active === 'payments' ? 'active' : '')}>
                            <Link to="/payments" className='sidebar-link'>
                                <i className="bi bi-currency-dollar" />
                                <span>Payments</span>
                            </Link>
                        </li>

                        <li className={"sidebar-item " + (active === 'correct-words' ? 'active' : '')}>
                            <Link to="/correct-words" className='sidebar-link'>
                                <i className="bi bi-grid-fill" />
                                <span>Correct Words</span>
                            </Link>
                        </li>

                        <li className={"sidebar-item " + (active === 'news' ? 'active' : '')}>
                            <Link to="/news" className='sidebar-link'>
                                <i className="bi bi-chat-dots-fill"/>
                                <span>News</span>
                            </Link>
                        </li>

                        <li className="sidebar-title" />

                        <li className={"sidebar-item"}>
                            <button className='sidebar-link btn btn-default' onClick={logout} style={{'cursor': 'pointer'}}>
                                <i className="bi bi-backspace-fill"/>
                                <span>Logout</span>
                            </button>
                        </li>

                    </ul>
                </div>

                <button className="sidebar-toggler btn x">
                    <i data-feather="x"/>
                </button>
            </div>
        </div>
    );

};

export default SideMenu;
