import React, {useState, useEffect} from "react";
import ApiService from "../../services/api-service";
import Footer from "../../component/footer";
import SideMenu from "../../component/side-menu";
import MobileMenuButton from "../../component/mobile-menu-button";
import {useParams} from "react-router-dom";

const api = new ApiService();

const WalletEdit = () => {
    const [form, setForm] = useState({});
    const [success, setSuccess] = useState(false);

    const {id} = useParams();

    useEffect(() => {
        api.getWallet(id)
            .then(x => {
                if (x.response) {
                    setForm(x.response);
                }
            })
            .catch();
    }, [id]);

    const updateWallet = (e) => {
        e.preventDefault();
        api.editWallet(id, form)
            .then(x => {
                if (x.code === 100) {
                    setSuccess(true);
                    setForm({});
                }
            })
            .catch();
    };

    return (
        <div id="app">

            <SideMenu active={'wallets'}/>

            <div id="main">
                <MobileMenuButton />

                <div className="page-heading">
                    <div className="page-title">
                        <div className="row">
                            <div className="col-12 col-md-6 order-md-1 order-last">
                                <h3>Edit Wallet</h3>
                            </div>
                        </div>
                    </div>

                    <section id="basic-horizontal-layouts">
                        <div className="row match-height">
                            <div className="col-md-12 col-12">
                                <div className="card">
                                    <div className="card-content">
                                        <div className="card-body">
                                            <form className="form form-horizontal" onSubmit={updateWallet}>
                                                <div className="form-body">

                                                    <div className="row">

                                                        <div className="col-md-4">
                                                            <label>Address</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="form-group has-icon-left">
                                                                <div className="position-relative">
                                                                    <input type="text" className="form-control"
                                                                           placeholder="Address"
                                                                           value={form.address}
                                                                           onChange={(e) => setForm((o) => ({
                                                                               ...o,
                                                                               address: e.target.value
                                                                           }))}
                                                                           id="first-name-icon"/>
                                                                    <div className="form-control-icon">
                                                                        <i className="bi bi-wallet2"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <label>USDT</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="form-group has-icon-left">
                                                                <div className="position-relative">
                                                                    <input type="number" className="form-control"
                                                                           placeholder="USDT"
                                                                           value={form.money}
                                                                           onChange={(e) => setForm((o) => ({
                                                                               ...o,
                                                                               money: e.target.value
                                                                           }))}
                                                                    />
                                                                    <div className="form-control-icon">
                                                                        <i className="bi bi-currency-dollar"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <label>Symbol</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="form-group has-icon-left">
                                                                <div className="position-relative">
                                                                    <input type="text" className="form-control"
                                                                           placeholder="Symbol"
                                                                           value={form.symbol}
                                                                           onChange={(e) => setForm((o) => ({
                                                                               ...o,
                                                                               symbol: e.target.value
                                                                           }))}
                                                                    />
                                                                    <div className="form-control-icon">
                                                                        <i className="bi bi-currency-bitcoin"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <label>Quantity</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="form-group has-icon-left">
                                                                <div className="position-relative">
                                                                    <input type="number" className="form-control"
                                                                           placeholder="Quantity"
                                                                           value={form.quantity}
                                                                           onChange={(e) => setForm((o) => ({
                                                                               ...o,
                                                                               quantity: e.target.value
                                                                           }))}
                                                                    />
                                                                    <div className="form-control-icon">
                                                                        <i className="bi bi-speedometer2" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="col-md-4">
                                                            <label>Status</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="form-group has-icon-left">
                                                                <div className="position-relative">
                                                                    <select className="form-control"
                                                                              value={form.active}
                                                                              onChange={(e) => setForm((o) => ({
                                                                                  ...o,
                                                                                  active: e.target.value
                                                                              }))}
                                                                    >
                                                                        <option value={''}>Select</option>
                                                                        <option value={'true'}>Active</option>
                                                                        <option value={'false'}>Passive</option>
                                                                    </select>
                                                                    <div className="form-control-icon">
                                                                        <i className="bi bi-lock"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 d-flex justify-content-end">
                                                            <button type="submit" className="btn btn-primary me-1 mb-1">
                                                                Submit
                                                            </button>
                                                        </div>

                                                        {success && (
                                                        <div className="alert alert-light-success color-success" style={{marginTop: 20}}>
                                                            <i className="bi bi-check-circle"/> Wallet Updated successfully.
                                                        </div>)}

                                                    </div>

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>

                <Footer/>
            </div>
        </div>
    );

};

export default WalletEdit;
