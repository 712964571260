import React, {useEffect, useState} from "react";
import ApiService from "../../services/api-service";
import Footer from "../../component/footer";
import SideMenu from "../../component/side-menu";
import {Link, useSearchParams} from "react-router-dom";
import MobileMenuButton from "../../component/mobile-menu-button";
import {dateFormat, getCountryFlag} from "../../services/helper";
import Pagination from "../../component/pagination";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import UserAgentParser from 'ua-parser-js';

const MySwal = withReactContent(Swal);

const api = new ApiService();

const Users = () => {

    const [users, setUsers] = useState([]);
    const [page, setPage] = useState({});

    const [queryParams] = useSearchParams();
    const pageNumber = queryParams.get('p');

    const getUsers = (p) => {
        api.users(p)
            .then(x => {
                if (x.response) {
                    setUsers(x.response.content);
                    setPage(x.response);
                }
            })
            .catch();
    };

    useEffect(() => {
        getUsers(pageNumber);
    }, [pageNumber]);

    const channelIcon = (channel) => {
        if (channel === 'GOOGLE') {
            return <i className="bi bi-google" title={'Google'}/>;
        }
        if (channel === 'TWITTER') {
            return <i className="bi bi-twitter" title={'Twitter'}/>;
        }
        if (channel === 'FACEBOOK') {
            return <i className="bi bi-facebook" title={'Facebook'}/>;
        }
        if (channel === 'LOGIN_FORM') {
            return <i className="bi bi-card-checklist" title={'Login Form'}/>;
        }
        else {
            return channel;
        }
    };

    const deleteUser = id => {
        MySwal.fire({
            title: <p>User will delete, are you sure?</p>,
            confirmButtonText: 'Delete',
            confirmButtonColor: '#dc3545',
            showCloseButton: true,
            showCancelButton: true,
            backdrop: true,
        }).then((result) => {
            if (result.isConfirmed) {
                api.deleteUser(id)
                    .then(x => {
                        if (x.code === 100) {
                            getUsers();
                        }
                    })
                    .catch();
            }
        })
    };

    const userAgent = (ua) => {
        if (!ua) {
            return (<div><i className={'bi bi-calculator'} /> Undefined</div>);
        }
        const parser = new UserAgentParser();
        const device = parser.setUA(ua).getDevice();
        return (<div><i className={'bi bi-phone'}/> {`${device.vendor ?? ''} ${device.model}`}</div>);
    };

    const showDetails = user => {
        Promise.all([api.userItems(user.id), api.userPoints(user.id)])
            .then(([items, points]) => {
                console.log(items, points);

                // Prepare Items
                const itemsHtml = items.response.map(item => {
                    return `<tr>
                        <td>${item.type}</td>
                        <td>${item.count}</td>
                        <td>${dateFormat(item.lastDate)}</td>
                    </tr>`;
                });

                // Prepare Points
                const pointsHistoryHtml = points.response?.history?.map(item => {
                    return `<tr>
                        <td>${item.type}</td>
                        <td>${item.points}</td>
                        <td>${dateFormat(item.date)}</td>
                        <!--<td>${item.details}</td>-->
                    </tr>`;
                });

                // Modal
                MySwal.fire({
                    title: `<p>User details: ${user.email}</p>`,
                    showCloseButton: true,
                    showCancelButton: false,
                    showConfirmButton: false,
                    backdrop: true,
                    width: 700,
                    html: `
                <h3>Details</h3>
                <table class="table">
                    <tr>
                        <th>Reference</th>
                        <th>Reference User</th>
                        <th>Points</th>
                        <th>Game Count</th>
                    </tr>
                    <tr>
                        <td>${user.reference}</td>
                        <td>${user.referenceUserId}</td>
                        <td>${points?.response?.points}</td>
                        <td>${points?.response?.gameCount}</td>
                    </tr>
                </table>
                <br/>
                <h3>User Items</h3>
                <table class="table">
                    <tr>
                        <th>Type</th>
                        <th>Amount</th>
                        <th>Last Added Date</th>
                    </tr>
                    ${itemsHtml}
                </table>
                
                <h3>User Point History</h3>
                <table class="table">
                    <tr>
                        <th>Type</th>
                        <th>Points</th>
                        <th>Added Date</th>
                        <!--<th>Details</th>-->
                    </tr>
                    ${pointsHistoryHtml}
                </table>
            `,
                });

            });
    };

    return (
        <div id="app">

            <SideMenu active={'users'} />

            <div id="main">
                <MobileMenuButton />

                <div className="page-heading">
                    <div className="page-title">
                        <div className="row">
                            <div className="col-12 col-md-6 order-md-1 order-last">
                                <h3>Users ({page.totalElements})</h3>
                            </div>
                            <div className="col-12 col-md-6 order-md-2 order-first" style={{textAlign: 'right'}}>
                                <Link to="/users/create" className={'btn btn-success'}>New User</Link>
                            </div>
                        </div>
                    </div>

                    <section className="section">
                        <div className="row" id="basic-table">
                            <div className="col-12 col-md-12">
                                <div className="card">
                                    <div className="card-content">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-lg">
                                                    <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Status</th>
                                                        <th>Country</th>
                                                        <th>Email</th>
                                                        <th style={{width: 120}}>Role</th>
                                                        <th>Channel</th>
                                                        <th>Device</th>
                                                        {/*<th style={{width: 170}}>Reference No</th>*/}
                                                        {/*<th>Reference User</th>*/}
                                                        <th>Created At</th>
                                                        <th>Details</th>
                                                        <th style={{width: 185}}></th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>

                                                    {users.map(x => (
                                                        <tr>
                                                            <td>{x.id}</td>
                                                            <td>
                                                                {x.isActive ?
                                                                    <span className="badge bg-light-success">Active</span>
                                                                    :
                                                                    <span className="badge bg-light-danger">Passive</span>}
                                                            </td>
                                                            <td>{getCountryFlag(x.country)}</td>
                                                            <td className="text-bold-500">
                                                                {x.email}
                                                                <br/>
                                                                {x.firstName} {x.lastName}
                                                            </td>
                                                            <td>
                                                                {x.role === 'ADMIN' &&
                                                                    <span className="badge bg-light-success">
                                                                        <i className="bi bi-emoji-sunglasses"/> ADMIN
                                                                    </span>
                                                                }
                                                                {x.role !== 'ADMIN' && x.role}
                                                            </td>
                                                            <td>{channelIcon(x.channel)}</td>
                                                            <td>{userAgent(x.userAgent)}</td>
                                                            <td title={x.createdAt}>
                                                                {dateFormat(x.createdAt)}
                                                            </td>
                                                            <td>
                                                                <button onClick={() => showDetails(x)} className={'btn btn-info btn-sm'} style={{marginLeft: 10}}>
                                                                    Details
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <Link to={'/users/edit/' + x.id} className={'btn btn-primary btn-sm'}>
                                                                    Edit
                                                                </Link>
                                                                <button onClick={() => deleteUser(x.id)} className={'btn btn-danger btn-sm'} style={{marginLeft: 10}}>
                                                                    Delete
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <Pagination {...page} />
                                </div>
                            </div>
                        </div>
                    </section>

                </div>

                <Footer />
            </div>
        </div>
    );

};

export default Users;
