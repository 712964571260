import moment from "moment";
import CountryFlags from "./country-flags";
import React from "react";

export const dateFormat = (date) => {
    return moment.utc(date.replace('T', ' '))
        .local()
        .startOf('seconds')
        .fromNow()
};

export const dateTimeFormat = date => {
    return moment.utc(date.replace('T', ' '))
        .format('DD MMMM YYYY, HH:mm:ss');
};

export const getCountryFlag = country => {
    if (country === "") {
        return <img src={CountryFlags['TR']} style={{width: 24, marginRight: 10}} alt={'country flag TR'} title={'TR'}/>
    }

    const fl = CountryFlags[country];

    if (fl) {
        return <img src={fl} style={{width: 24, marginRight: 10}} alt={'country flag ' + country} title={country}/>
    }

    return country;
};