import React, {useState} from "react";
import ApiService from "../../services/api-service";
import Footer from "../../component/footer";
import SideMenu from "../../component/side-menu";
import MobileMenuButton from "../../component/mobile-menu-button";

const api = new ApiService();

const UserCreate = () => {
    const [form, setForm] = useState({});
    const [success, setSuccess] = useState(false);

    const createUser = (e) => {
        e.preventDefault();
        api.createUser(form)
            .then(x => {
                if (x.code === 100) {
                    setSuccess(true);
                    setForm({});
                }
            })
            .catch();
    };

    return (
        <div id="app">

            <SideMenu active={'users'}/>

            <div id="main">
                <MobileMenuButton />

                <div className="page-heading">
                    <div className="page-title">
                        <div className="row">
                            <div className="col-12 col-md-6 order-md-1 order-last">
                                <h3>Create User</h3>
                            </div>
                        </div>
                    </div>

                    <section id="basic-horizontal-layouts">
                        <div className="row match-height">
                            <div className="col-md-12 col-12">
                                <div className="card">
                                    <div className="card-content">
                                        <div className="card-body">
                                            <form className="form form-horizontal" onSubmit={createUser}>
                                                <div className="form-body">

                                                    <div className="row">

                                                        <div className="col-md-4">
                                                            <label>Email</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="form-group has-icon-left">
                                                                <div className="position-relative">
                                                                    <input type="text" className="form-control"
                                                                           placeholder="Email address"
                                                                           value={form.email}
                                                                           onChange={(e) => setForm((o) => ({
                                                                               ...o,
                                                                               email: e.target.value
                                                                           }))}
                                                                           id="first-name-icon"/>
                                                                    <div className="form-control-icon">
                                                                        <i className="bi bi-envelope"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <label>First Name</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="form-group has-icon-left">
                                                                <div className="position-relative">
                                                                    <input type="text" className="form-control"
                                                                           placeholder="First name"
                                                                           value={form.firstName}
                                                                           onChange={(e) => setForm((o) => ({
                                                                               ...o,
                                                                               firstName: e.target.value
                                                                           }))}
                                                                    />
                                                                    <div className="form-control-icon">
                                                                        <i className="bi bi-person"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <label>Last Name</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="form-group has-icon-left">
                                                                <div className="position-relative">
                                                                    <input type="text" className="form-control"
                                                                           placeholder="Last name"
                                                                           value={form.lastName}
                                                                           onChange={(e) => setForm((o) => ({
                                                                               ...o,
                                                                               lastName: e.target.value
                                                                           }))}
                                                                    />
                                                                    <div className="form-control-icon">
                                                                        <i className="bi bi-person"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <label>Password</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="form-group has-icon-left">
                                                                <div className="position-relative">
                                                                    <input type="password" className="form-control"
                                                                           placeholder="Password"
                                                                           value={form.password}
                                                                           onChange={(e) => setForm((o) => ({
                                                                               ...o,
                                                                               password: e.target.value
                                                                           }))}
                                                                    />
                                                                    <div className="form-control-icon">
                                                                        <i className="bi bi-lock"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <label>Role</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="form-group has-icon-left">
                                                                <div className="position-relative">
                                                                    <select className="form-control"
                                                                           value={form.role}
                                                                           onChange={(e) => setForm((o) => ({
                                                                               ...o,
                                                                               role: e.target.value
                                                                           }))}
                                                                    >
                                                                        <option value={'ADMIN'}>ADMIN</option>
                                                                        <option value={'WALLET_OWNER'}>WALLET_OWNER</option>
                                                                    </select>
                                                                    <div className="form-control-icon">
                                                                        <i className="bi bi-lock"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 d-flex justify-content-end">
                                                            <button type="submit" className="btn btn-primary me-1 mb-1">
                                                                Submit
                                                            </button>
                                                        </div>

                                                        {success && (
                                                        <div className="alert alert-light-success color-success" style={{marginTop: 20}}>
                                                            <i className="bi bi-check-circle"/> User created successfully.
                                                        </div>)}

                                                    </div>

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>

                <Footer/>
            </div>
        </div>
    );

};

export default UserCreate;
