import React, {useEffect, useState} from "react";
import SideMenu from "../component/side-menu";
import Footer from "../component/footer";
import ApiService, {ADMIN_EMAIL_ADDRESS} from "../services/api-service";
import MobileMenuButton from "../component/mobile-menu-button";
import {getCountryFlag} from "../services/helper";

const api = new ApiService();

const Home = () => {

    const [stats, setStats] = useState({});
    const [adminEmail] = useState(
        localStorage.getItem(ADMIN_EMAIL_ADDRESS)
    )

    const adminFace = () => {
        const rndInt = Math.floor(Math.random() * 4) + 1;
        return `/assets/images/faces/${rndInt}.jpg`
    }

    useEffect(() => {
        api.stats()
            .then(x => {
                if (x.response) {
                    setStats(x.response);
                }
            })
            .catch();
    }, []);

    return (
        <div id="app">

            <SideMenu active={'dashboard'}/>

            <div id="main">
                <MobileMenuButton />

                <div className="page-heading">
                    <h3>Dashboard</h3>
                </div>
                <div className="page-content">
                    <section className="row">
                        <div className="col-12 col-lg-9">
                            <div className="row">
                                <div className="col-6 col-lg-3 col-md-6">
                                    <div className="card">
                                        <div className="card-body px-3 py-4-5">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="stats-icon purple">
                                                        <i className="iconly-boldShow"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <h6 className="text-muted font-semibold">Games</h6>
                                                    <h6 className="font-extrabold mb-0">{stats.games}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-6">
                                    <div className="card">
                                        <div className="card-body px-3 py-4-5">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="stats-icon blue">
                                                        <i className="iconly-boldProfile"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <h6 className="text-muted font-semibold">Users</h6>
                                                    <h6 className="font-extrabold mb-0">{stats.users}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-6">
                                    <div className="card">
                                        <div className="card-body px-3 py-4-5">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="stats-icon green">
                                                        <i className="iconly-boldAdd-User"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <h6 className="text-muted font-semibold">New Users (Today)</h6>
                                                    <h6 className="font-extrabold mb-0">{stats.usersRegisteredToday}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-6">
                                    <div className="card">
                                        <div className="card-body px-3 py-4-5">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="stats-icon red">
                                                        <i className="iconly-boldBookmark"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <h6 className="text-muted font-semibold">Active Wallets</h6>
                                                    <h6 className="font-extrabold mb-0">{stats.wallets}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-xl-4">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4>User Countries</h4>
                                        </div>
                                        <div className="card-body">

                                            {stats.userCountries?.map(x => (
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="d-flex align-items-center">
                                                            <h5 className="mb-0 ms-3">
                                                                {getCountryFlag(x.country)}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 text-center">
                                                        <h5 className="mb-0">{x.count}</h5>
                                                    </div>
                                                    <div className="col-12">
                                                        <div id="chart-europe"></div>
                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-8">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4>.</h4>
                                        </div>
                                        <div className="card-body">
                                            .
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3">
                            <div className="card">
                                <div className="card-body py-4 px-5">
                                    <div className="d-flex align-items-center">
                                        <div className="avatar avatar-xl">
                                            <img src={adminFace()} alt="Face 1"/>
                                        </div>
                                        <div className="ms-3 name">
                                            <h5 className="font-bold">Admin</h5>
                                            <h6 className="text-muted mb-0">{adminEmail}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <h4>.</h4>
                                </div>
                                <div className="card-content pb-4" />
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />

            </div>
        </div>
    );

};


export default Home;
