import React from "react";

const Footer = () => {

    return (
        <footer>
            <div className="footer clearfix mb-0 text-muted">
                <div className="float-start">
                    <p>2022 &copy; Wordle Wallet</p>
                </div>
                <div className="float-end" />
            </div>
        </footer>
    );

};

export default Footer;