import React, {useState} from "react";
import ApiService from "../../services/api-service";
import Footer from "../../component/footer";
import SideMenu from "../../component/side-menu";
import MobileMenuButton from "../../component/mobile-menu-button";

const api = new ApiService();
const SUCCESS = 'success';
const DANGER = 'danger';

const WalletCreate = () => {
    const [form, setForm] = useState({});
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [wordList, setWordList] = useState([]);

    const createWallet = (e) => {
        e.preventDefault();
        setError(null);
        setSuccess(false);

        if (checkIncorrectWord()) {
            setError('Looks like the wrong word was entered');
            return;
        }

        api.createWallet(form)
            .then(x => {
                if (x.code === 100) {
                    setSuccess(true);
                    setForm((w) => ({...w, address: '', words: ''}));
                    setWordList([]);
                    setError(null);
                }
            })
            .catch(e => {
                // todo : error handling
            });
    };

    const listWords = (text) => {
        let words = text.split(" ");
        let list = [];
        const regex = /^(\w+)$/;

        words.forEach(x => {
            let o = {
                text: x,
                class: (regex.test(x) ? SUCCESS : DANGER),
            };
            list.push(o);
        });

        setWordList(list);
    };

    const checkIncorrectWord = () => {
        if (wordList.length === 0) {
            return true;
        }
        return wordList.some(x => x.class === DANGER);
    };

    return (
        <div id="app">

            <SideMenu active={'wallets'}/>

            <div id="main">
                <MobileMenuButton />

                <div className="page-heading">
                    <div className="page-title">
                        <div className="row">
                            <div className="col-12 col-md-6 order-md-1 order-last">
                                <h3>Create Wallet</h3>
                            </div>
                        </div>
                    </div>

                    <section id="basic-horizontal-layouts">
                        <div className="row match-height">
                            <div className="col-md-12 col-12">
                                <div className="card">
                                    <div className="card-content">
                                        <div className="card-body">
                                            <form className="form form-horizontal" onSubmit={createWallet}>
                                                <div className="form-body">

                                                    <div className="row">

                                                        <div className="col-md-4">
                                                            <label>Address</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="form-group has-icon-left">
                                                                <div className="position-relative">
                                                                    <input type="text" className="form-control"
                                                                           placeholder="Address"
                                                                           value={form.address}
                                                                           onChange={(e) => setForm((o) => ({
                                                                               ...o,
                                                                               address: e.target.value
                                                                           }))}
                                                                           id="first-name-icon"/>
                                                                    <div className="form-control-icon">
                                                                        <i className="bi bi-wallet2"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <label>USDT</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="form-group has-icon-left">
                                                                <div className="position-relative">
                                                                    <input type="number" className="form-control"
                                                                           placeholder="USDT"
                                                                           value={form.money}
                                                                           onChange={(e) => setForm((o) => ({
                                                                               ...o,
                                                                               money: e.target.value
                                                                           }))}
                                                                    />
                                                                    <div className="form-control-icon">
                                                                        <i className="bi bi-currency-dollar"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <label>Symbol</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="form-group has-icon-left">
                                                                <div className="position-relative">
                                                                    <input type="text" className="form-control"
                                                                           placeholder="Symbol"
                                                                           value={form.symbol}
                                                                           onChange={(e) => setForm((o) => ({
                                                                               ...o,
                                                                               symbol: e.target.value
                                                                           }))}
                                                                    />
                                                                    <div className="form-control-icon">
                                                                        <i className="bi bi-currency-bitcoin"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <label>Quantity</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="form-group has-icon-left">
                                                                <div className="position-relative">
                                                                    <input type="number" className="form-control"
                                                                           placeholder="Quantity"
                                                                           value={form.quantity}
                                                                           onChange={(e) => setForm((o) => ({
                                                                               ...o,
                                                                               quantity: e.target.value
                                                                           }))}
                                                                    />
                                                                    <div className="form-control-icon">
                                                                        <i className="bi bi-speedometer2" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <label>Wallet Type</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="form-group has-icon-left">
                                                                <div className="position-relative">
                                                                    <select className="form-control"
                                                                           value={form.type}
                                                                           onChange={(e) => setForm((o) => ({
                                                                               ...o,
                                                                               type: e.target.value
                                                                           }))}
                                                                    >
                                                                        <option value={''}>Select</option>
                                                                        <option value={'STANDARD'}>Standard</option>
                                                                        <option value={'PREMIUM'}>Premium</option>
                                                                    </select>
                                                                    <div className="form-control-icon">
                                                                        <i className="bi bi-star" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        {form.type === 'PREMIUM' ? (
                                                            <>
                                                                <div className="col-md-4">
                                                                    <label>Points</label>
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <div className="form-group has-icon-left">
                                                                        <div className="position-relative">
                                                                            <input type="number" className="form-control"
                                                                                   placeholder="Points"
                                                                                   value={form.points}
                                                                                   onChange={(e) => setForm((o) => ({
                                                                                       ...o,
                                                                                       points: e.target.value
                                                                                   }))}
                                                                            />
                                                                            <div className="form-control-icon">
                                                                                <i className="bi bi-star" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : null}

                                                        <div className="col-md-4">
                                                            <label>Words</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="form-group has-icon-left">
                                                                <div className="position-relative">
                                                                    <textarea className="form-control"
                                                                              placeholder="Words"
                                                                              value={form.words}
                                                                              onChange={(e) => {
                                                                                  setForm((o) => ({...o, words: e.target.value}));
                                                                                  listWords(e.target.value);
                                                                              }}
                                                                    />
                                                                    <div className="form-control-icon">
                                                                        <i className="bi bi-lock"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={'row badges'} style={{marginBottom: 20}}>
                                                                {wordList.map(w => (
                                                                    <div className={'col-md-2'}>
                                                                        <span className={"badge bg-" + w.class} style={{marginBottom: 10}}>
                                                                            {w.class === SUCCESS
                                                                                ? <i className="bi bi-check2-circle" />
                                                                                : <i className="bi bi-x-circle"/>} {w.text}
                                                                        </span>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>

                                                        <div className="col-12 d-flex justify-content-end">
                                                            <button type="submit" className="btn btn-primary me-1 mb-1">
                                                                Submit
                                                            </button>
                                                        </div>

                                                        {success && (
                                                        <div className="alert alert-light-success color-success" style={{marginTop: 20}}>
                                                            <i className="bi bi-check-circle"/> Wallet created successfully.
                                                        </div>)}

                                                        {error && (
                                                            <div className="alert alert-light-danger color-danger" style={{marginTop: 20}}>
                                                                <i className="bi bi-exclamation-circle"></i> {error}
                                                            </div>)}

                                                    </div>

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>

                <Footer/>
            </div>
        </div>
    );

};

export default WalletCreate;
