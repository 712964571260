import React, {useContext, useEffect, useState} from "react";
import ApiService, {ADMIN_EMAIL_ADDRESS} from "../../services/api-service";
import {AuthContext} from "../../services/auth-context";
import { useNavigate } from "react-router-dom";

import "./auth.css";
const api = new ApiService();

const Login = () => {

    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const {signIn} = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        const body = document.getElementsByTagName('body')[0];

        body.className = 'bg-white';
        return () => {
            body.className = '';
        };
    }, []);

    const login = (e) => {
        e.preventDefault();
        localStorage.setItem(ADMIN_EMAIL_ADDRESS, email);
        api.login(email, pass)
            .then(x => {
                if (x.access_token) {
                    signIn(x.access_token);
                    navigate("/", { replace: true });
                }
            })
            .catch();
    };

    return (
        <div id="auth">

            <div className="row h-100">
                <div className="col-lg-5 col-12">
                    <div id="auth-left">
                        <h1 className="auth-title">Log in</h1>
                        <p className="auth-subtitle mb-5">Log in with your data that you entered during
                            registration.</p>

                        <form onSubmit={login}>
                            <div className="form-group position-relative has-icon-left mb-4">
                                <input type="text" className="form-control form-control-xl" placeholder="Username" value={email} onChange={(e) => setEmail(e.target.value)}/>
                                    <div className="form-control-icon">
                                        <i className="bi bi-person"/>
                                    </div>
                            </div>
                            <div className="form-group position-relative has-icon-left mb-4">
                                <input type="password" className="form-control form-control-xl" placeholder="Password" value={pass} onChange={(e) => setPass(e.target.value)}/>
                                    <div className="form-control-icon">
                                        <i className="bi bi-shield-lock"/>
                                    </div>
                            </div>
                            <div className="form-check form-check-lg d-flex align-items-end">
                                <input className="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault" />
                                    <label className="form-check-label text-gray-600" htmlFor="flexCheckDefault">
                                        Keep me logged in
                                    </label>
                            </div>
                            <button className="btn btn-primary btn-block btn-lg shadow-lg mt-5">Log in</button>
                        </form>
                        <div className="text-center mt-5 text-lg fs-4">
                            <p className="text-gray-600">Don't have an account? <a href="auth-register.html"
                                                                                   className="font-bold">Sign
                                up</a>.</p>
                            <p><a className="font-bold" href="auth-forgot-password.html">Forgot password?</a>.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 d-none d-lg-block">
                    <div id="auth-right">
                        x
                    </div>
                </div>
            </div>

        </div>
);

};


export default Login;