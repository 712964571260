import React from "react";
import {Route, Routes} from "react-router-dom";
import Home from "./page/home";
import Login from "./page/login/login";
import PrivateRoute from "./private-route";
import Wallets from "./page/wallet/wallets";
import Users from "./page/user/users";
import Games from "./page/game/games";
import News from "./page/news/news";
import WalletCreate from "./page/wallet/wallet-create";
import GameHistory from "./page/game/game-history";
import UserCreate from "./page/user/user-create";
import NewsCreate from "./page/news/news-create";
import UserEdit from "./page/user/user-edit";
import WalletEdit from "./page/wallet/wallet-edit";
import NewsEdit from "./page/news/news-edit";
import Error404 from "./page/error/error-404";
import CorrectWords from "./page/correct-words/correct-words";
import Payments from "./page/payment/payments";

const Router = () => {
    return (
        <Routes>
            <Route exact path={"/"} element={<PrivateRoute><Home/></PrivateRoute>}/>

            <Route exact path={"/wallets"} element={<PrivateRoute><Wallets/></PrivateRoute>}/>
            <Route exact path={"/wallets/create"} element={<PrivateRoute><WalletCreate/></PrivateRoute>}/>
            <Route exact path={"/wallets/edit/:id"} element={<PrivateRoute><WalletEdit/></PrivateRoute>}/>

            <Route exact path={"/users"} element={<PrivateRoute><Users/></PrivateRoute>}/>
            <Route exact path={"/users/create"} element={<PrivateRoute><UserCreate/></PrivateRoute>}/>
            <Route exact path={"/users/edit/:id"} element={<PrivateRoute><UserEdit/></PrivateRoute>}/>

            <Route exact path={"/games"} element={<PrivateRoute><Games/></PrivateRoute>}/>
            <Route exact path={"/games/history/:id"} element={<PrivateRoute><GameHistory/></PrivateRoute>}/>

            <Route exact path={"/payments"} element={<PrivateRoute><Payments/></PrivateRoute>}/>

            <Route exact path={"/news"} element={<PrivateRoute><News/></PrivateRoute>}/>
            <Route exact path={"/news/create"} element={<PrivateRoute><NewsCreate/></PrivateRoute>}/>
            <Route exact path={"/news/edit/:id"} element={<PrivateRoute><NewsEdit/></PrivateRoute>}/>

            <Route exact path={"/correct-words"} element={<PrivateRoute><CorrectWords/></PrivateRoute>}/>

            <Route exact path={"/login"} element={<Login/>}/>
            <Route path={"*"} element={<Error404/>}/>
        </Routes>
    );

};

export default Router;