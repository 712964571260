// https://www.worldometers.info/geography/flags-of-the-world/
const flags = {
    TR: 'https://www.worldometers.info/img/flags/small/tn_tu-flag.gif',
    UK: 'https://www.worldometers.info/img/flags/small/tn_uk-flag.gif',
    GB: 'https://www.worldometers.info/img/flags/small/tn_uk-flag.gif',
    US: 'https://www.worldometers.info/img/flags/small/tn_us-flag.gif',
    ID: 'https://www.worldometers.info/img/flags/small/tn_id-flag.gif',
    DZ: 'https://www.worldometers.info/img/flags/small/tn_ag-flag.gif',
    AZ: 'https://www.worldometers.info/img/flags/small/tn_aj-flag.gif',
    BR: 'https://www.worldometers.info/img/flags/small/tn_br-flag.gif',
    RU: 'https://www.worldometers.info/img/flags/small/tn_rs-flag.gif',
    IR: 'https://www.worldometers.info/img/flags/small/tn_ir-flag.gif',
    IE: 'https://www.worldometers.info/img/flags/small/tn_ei-flag.gif',
    CN: 'https://www.worldometers.info/img/flags/small/tn_ch-flag.gif',
};

export default flags;
