
export const AUTH_TOKEN = "xmN2wRF9tbHHwSyY3tt";
export const ADMIN_EMAIL_ADDRESS = 'Qsc5_90';

let API_URL;
if (process.env.REACT_APP_STAGE === "dev") {
  API_URL = "http://localhost:8880";
} else {
  API_URL = "https://api.wordlewallet.com";
}

class ApiService {

  getToken() {
      return localStorage.getItem(AUTH_TOKEN);
  }

  deleteToken() {
    localStorage.removeItem(AUTH_TOKEN);
  }

  static POST = 'POST';
  static GET = 'GET';
  static Authorization = 'Authorization';

  async login(email, pass) {
    return await this._call(
      '/oauth/token?grant_type=client_credentials',
      ApiService.POST,
      {},
      {
          'Authorization': 'Basic ' + btoa(`${email}:${pass}`),
      },
    );
  }

  async wallets(p, excel = false) {
    return await this._call(
      '/api/v1/admin/wallets' + p + '&e=' + (excel ? 1 : 0),
      ApiService.GET,
      null,
      this._defaultHeaders(),
    );
  }

  async users(p) {
    return await this._call(
        '/api/v1/admin/users?p=' + (p || 1),
        ApiService.GET,
        null,
        this._defaultHeaders(),
    );
  }

  async games(p) {
    return await this._call(
        '/api/v1/admin/games?p=' + (p || 1),
        ApiService.GET,
        null,
        this._defaultHeaders(),
    );
  }

  async news(p) {
    return await this._call(
        '/api/v1/admin/news?p=' + (p || 1),
        ApiService.GET,
        null,
        this._defaultHeaders(),
    );
  }

  async stats() {
    return await this._call(
        '/api/v1/admin/stats',
        ApiService.GET,
        null,
        this._defaultHeaders(),
    );
  }

  async createWallet(form) {
    return await this._call(
        '/api/v1/admin/wallets',
        ApiService.POST,
        form,
        this._defaultHeaders(),
    );
  }

  async createUser(form) {
    return await this._call(
        '/api/v1/admin/users',
        ApiService.POST,
        form,
        this._defaultHeaders(),
    );
  }

  async createNews(form) {
    return await this._call(
        '/api/v1/admin/news',
        ApiService.POST,
        form,
        this._defaultHeaders(),
    );
  }

  async gameHistory(id) {
    return await this._call(
        '/api/v1/admin/games/history/' + id,
        ApiService.GET,
        null,
        this._defaultHeaders(),
    );
  }

  async getUser(id) {
    return await this._call(
        '/api/v1/admin/users/detail/' + id,
        ApiService.GET,
        null,
        this._defaultHeaders(),
    );
  }

  async editUser(id, form) {
    return await this._call(
        '/api/v1/admin/users/edit',
        ApiService.POST,
        {...form, id: id},
        this._defaultHeaders(),
    );
  }

  async setUserPoints(id, points) {
    return await this._call(
        '/api/v1/admin/users/edit-points',
        ApiService.POST,
        {id, points},
        this._defaultHeaders(),
    );
  }

  async getWallet(id) {
    return await this._call(
        '/api/v1/admin/wallets/detail/' + id,
        ApiService.GET,
        null,
        this._defaultHeaders(),
    );
  }

  async editWallet(id, form) {
    return await this._call(
        '/api/v1/admin/wallets/edit',
        ApiService.POST,
        {...form, id: id},
        this._defaultHeaders(),
    );
  }

  async updateNews(id, form) {
    return await this._call(
        '/api/v1/admin/news/edit',
        ApiService.POST,
        {...form, id: id},
        this._defaultHeaders(),
    );
  }

  async getNews(id) {
    return await this._call(
        '/api/v1/admin/news/detail/' + id,
        ApiService.GET,
        null,
        this._defaultHeaders(),
    );
  }

  async deleteUser(id) {
    return await this._call(
        '/api/v1/admin/users/delete/' + id,
        ApiService.GET,
        null,
        this._defaultHeaders(),
    );
  }

  async correctWords(p) {
    return await this._call(
        '/api/v1/admin/correct-words?p=' + (p || 1),
        ApiService.GET,
        null,
        this._defaultHeaders(),
    );
  }

  async deleteCorrectWord(id) {
    return await this._call(
        '/api/v1/admin/correct-words/delete',
        ApiService.POST,
        {id: id},
        this._defaultHeaders(),
    );
  }

  async payments(p) {
    return await this._call(
        '/api/v1/admin/payments?p=' + (p || 1),
        ApiService.GET,
        null,
        this._defaultHeaders(),
    );
  }

  async userItems(userId) {
    return await this._call(
        '/api/v1/admin/user-items?userId=' + userId,
        ApiService.GET,
        null,
        this._defaultHeaders(),
    );
  }

  async userPoints(userId) {
    return await this._call(
        '/api/v1/admin/user-points?userId=' + userId,
        ApiService.GET,
        null,
        this._defaultHeaders(),
    );
  }


    // ======================================================

  _defaultHeaders() {
    return {
      Authorization: 'Bearer ' + this.getToken(),
    };
  }

  async _call(endpoint, method, body, headers) {
    const _headers = {
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    // console.log(`[${endpoint}] request -> `, body);

    const res = await fetch(`${API_URL}${endpoint}`, {
      method: method,
      headers: {..._headers, ...headers},
      body: body ? JSON.stringify(body) : undefined,
      timeout: 10,
      compress: true,
    });

    const json = await res.json();
    // console.log(`[${endpoint}] response -> `, json);

    if (endpoint.search('oauth/token') !== 1 && json.code === 199) {
      localStorage.removeItem(AUTH_TOKEN);
      window.location.href = '/login';
    }

    return json;
  }
}

export default ApiService;
