import React, {useEffect, useState} from "react";
import ApiService from "../../services/api-service";
import Footer from "../../component/footer";
import SideMenu from "../../component/side-menu";
import {Link, useSearchParams} from "react-router-dom";
import MobileMenuButton from "../../component/mobile-menu-button";
import Pagination from "../../component/pagination";
import {dateFormat} from "../../services/helper";

const api = new ApiService();

const News = () => {
    const [news, setNews] = useState([]);
    const [page, setPage] = useState({});

    const [queryParams] = useSearchParams();
    const pageNumber = queryParams.get('p');

    useEffect(() => {
        api.news(pageNumber)
            .then(x => {
                if (x.response) {
                    setNews(x.response.content);
                    setPage(x.response);
                }
            })
            .catch();
    }, [pageNumber]);

    return (
        <div id="app">

            <SideMenu active={'news'} />

            <div id="main">
                <MobileMenuButton />

                <div className="page-heading">
                    <div className="page-title">
                        <div className="row">
                            <div className="col-12 col-md-6 order-md-1 order-last">
                                <h3>News ({page.totalElements})</h3>
                            </div>
                            <div className="col-12 col-md-6 order-md-2 order-first" style={{textAlign: 'right'}}>
                                <Link to="/news/create" className={'btn btn-success'}>Create</Link>
                            </div>
                        </div>
                    </div>

                    <section className="section">
                        <div className="row" id="basic-table">
                            <div className="col-12 col-md-12">
                                <div className="card">
                                    <div className="card-content">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-lg">
                                                    <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Title</th>
                                                        <th>Description</th>
                                                        <th>Category</th>
                                                        <th>Status</th>
                                                        <th>Created At</th>
                                                        <th style={{width: 50}}></th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>

                                                    {news.map(x => (
                                                        <tr>
                                                            <td>{x.id}</td>
                                                            <td className="text-bold-500">{x.title}</td>
                                                            <td className="text-bold-500">{x.desc}</td>
                                                            <td className="text-bold-500">{x.category}</td>
                                                            <td>
                                                                {x.isActive ?
                                                                    <span className="badge bg-light-success">Active</span>
                                                                    :
                                                                    <span className="badge bg-light-danger">Passive</span>}
                                                            </td>
                                                            <td title={x.createdAt}>
                                                                {dateFormat(x.createdAt)}
                                                            </td>
                                                            <td>
                                                                <Link to={'/news/edit/' + x.id} className={'btn btn-primary'}>
                                                                    Edit
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <Pagination {...page} />
                                </div>
                            </div>
                        </div>
                    </section>

                </div>

                <Footer />
            </div>
        </div>
    );

};

export default News;
