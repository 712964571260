import React, {useEffect, useState} from "react";
import ApiService from "../../services/api-service";
import Footer from "../../component/footer";
import SideMenu from "../../component/side-menu";
import {Link, useParams, useNavigate} from "react-router-dom";
import MobileMenuButton from "../../component/mobile-menu-button";
import {dateTimeFormat} from "../../services/helper";
import './game-history.css';

const api = new ApiService();

const bg = {
    '-1': '#787c7e',
    '0': '#c9b458',
    '1': '#6aaa64'
};

const GameHistory = () => {
    let navigate = useNavigate();
    const {id} = useParams();
    const [gameResults, setGameResults] = useState({});
    const [gameResultKeys, setGameResultKeys] = useState([]);

    useEffect(() => {
        api.gameHistory(id)
            .then(x => {
                if (x.response) {
                    const results = x.response.reduce(function (results, org) {
                        (results[org.step] = results[org.step] || []).push(org);
                        return results;
                    }, {})
                    setGameResults(results);
                    setGameResultKeys(Object.keys(results));
                }
            })
            .catch();
    }, [id]);

    const ths = (word, answer) => {
        const list = [];
        const a = JSON.parse(answer);
        word.split('').forEach((x, i) => {
            const an = a[i];
            list.push(<th className={'normal-th'} style={{backgroundColor: bg[an]}}>{x}</th>);
        });
        return list;
    };

    const fillEmptyTr = (arr) => {
        const th = (q) => {
            const l = [];
            for (let j = 0; j < q.length; j++) {
                l.push(<th className={'gray-th'}>X</th>);
            }
            return l;
        };

        const trList = [];
        if (arr[0] && arr[0].word) {
            const xl = arr[0].word.split('');
            const needTr = xl.length - arr.length;
            if (needTr > 0) {
                for (let i = 0; i < needTr; i++) {
                    trList.push(xl);
                }
            }
        }

        return (
            <>
                {trList.map(tr => (
                    <tr>
                        {th(tr)}
                    </tr>
                ))}
            </>
        );
    };

    const chanceUseClass = (word, index) => {
        const wl = word.split('').length;
        if (index > wl - 1) {
            return 'chance-used-tr';
        }
        return 'normal-tr';
    };

    return (
        <div id="app">

            <SideMenu active={'games'}/>

            <div id="main">
                <MobileMenuButton/>

                <div className="page-heading">
                    <div className="page-title">
                        <div className="row">
                            <div className="col-12 col-md-6 order-md-1 order-last">
                                <h3>Game History</h3>
                            </div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to={'/'}>Home</Link>
                                    </li>
                                    <a onClick={() => navigate(-1)} className="breadcrumb-item active" href={'#/g'}>
                                        Games
                                    </a>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <section className="section">
                        <div className="row" id="basic-table">
                            <div className="col-12 col-md-12">
                                <div className="card">
                                    <div className="card-content">
                                        <div className="card-body">
                                            <div className={'row'}>

                                                <div className={'col-md-12'}>
                                                    <div className="alert alert-light-warning color-warning">
                                                        <i className="bi bi-exclamation-triangle" />
                                                        {' '}The row which has a yellow border means user used a chance.
                                                    </div>
                                                </div>

                                                {gameResultKeys.map((x, i) => (
                                                    <div className={'col-md-3'} key={`did-${i}`}>
                                                        <div className={'border'} style={{margin: 20}}>
                                                            <h5 className={'text-center'}
                                                                style={{padding: 3}}>Step: {x}</h5>
                                                            <hr/>
                                                            <table className={'table table-bordered'}>
                                                                {gameResults[x].map((t, ti) => (
                                                                    <tr title={`Id = ${t.id}, Date = ${dateTimeFormat(t.createdAt)}`}
                                                                        key={`trid-${ti}`}
                                                                        className={chanceUseClass(t.word, ti)}
                                                                    >
                                                                        {ths(t.word, t.answer)}
                                                                    </tr>
                                                                ))}
                                                                {fillEmptyTr(gameResults[x])}
                                                            </table>
                                                        </div>
                                                    </div>
                                                ))}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>

                <Footer/>
            </div>
        </div>
    );
};

export default GameHistory;
