import React, {useEffect, useState} from "react";
import ApiService from "../../services/api-service";
import Footer from "../../component/footer";
import SideMenu from "../../component/side-menu";
import {Link, useSearchParams} from "react-router-dom";
import MobileMenuButton from "../../component/mobile-menu-button";
import Pagination from "../../component/pagination";
import CSVDownload from "react-csv/src/components/Download";

const api = new ApiService();

const Wallets = () => {

    const [wallets, setWallets] = useState([]);
    const [page, setPage] = useState({});

    const [queryParams] = useSearchParams();
    const pageNumber = queryParams.get('p');
    const [filterForm, setFilterForm] = useState({});

    useEffect(() => {
        let params = '?';

        queryParams.forEach((value, key) => {
            if (params !== '?') {
                params += "&";
            }
            params += key + "=" + value;
            setFilterForm((e) => ({...e, [key]: value}))
        });

        api.wallets(params)
            .then(x => {
                if (x.response) {
                    setWallets(x.response.content);
                    setPage(x.response);
                }
            })
            .catch();
    }, [pageNumber, queryParams]);

    const exportHeaders = [
        {label: 'ID', key: 'id'},
        {label: 'Active', key: 'active'},
        {label: 'Address', key: 'address'},
        {label: 'Symbol', key: 'symbol'},
        {label: 'Quantity', key: 'quantity'},
        {label: 'USDT', key: 'usd'},
        {label: 'Points', key: 'points'},
        {label: 'Type', key: 'type'},
    ];
    const [e, setE] = useState(null);
    const excelExport = () => {
        let params = '?';

        queryParams.forEach((value, key) => {
            if (params !== '?') {
                params += "&";
            }
            params += key + "=" + value;
            setFilterForm((e) => ({...e, [key]: value}))
        });

        api.wallets(params, true)
            .then(x => {
                if (x?.response?.content) {
                    const content = x.response.content.map(w => {
                        w.symbol = w.balances[0]?.symbol;
                        w.quantity = w.balances[0]?.quantity;
                        w.usd = w.balances[0]?.usd;
                        delete w.balances;
                        return w;
                    });
                    setE(<CSVDownload data={content} headers={exportHeaders} target="_blank"/>);
                }
            })
            .catch(console.error);
    };

    return (
        <div id="app">

            <SideMenu active={'wallets'}/>

            <div id="main">
                <MobileMenuButton/>

                <div className="page-heading">
                    <div className="page-title">
                        <div className="row">
                            <div className="col-12 col-md-6 order-md-1 order-last">
                                <h3>Wallets ({page.totalElements})</h3>
                            </div>
                            <div className="col-12 col-md-6 order-md-2 order-first" style={{textAlign: 'right'}}>
                                <button className={'btn btn-info'} style={{marginRight: 20}}
                                        onClick={() => excelExport()}>
                                    <i className={'bi bi-download'}/> Excel Export
                                </button>
                                {e}
                                <Link to="/wallets/create" className={'btn btn-success'}>New Wallet</Link>
                            </div>
                        </div>
                    </div>

                    <section className="section">
                        <div className="row" id="basic-table">
                            <div className="col-12 col-md-12">
                                <div className="card">
                                    <div className="card-content">
                                        <div className="card-body">

                                            <div className="table-responsive">

                                                <form method={'get'}>
                                                    <table className="table table-lg">
                                                        <thead>
                                                        <tr>
                                                            <th>Status</th>
                                                            <th>Address</th>
                                                            <th>Type</th>
                                                            <th>Details</th>
                                                            <th style={{width: 50}}></th>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <select className={'form-control'} name={'status'}
                                                                        value={filterForm.status}
                                                                        onChange={(e) => setFilterForm((o) => ({
                                                                            ...o,
                                                                            status: e.target.value
                                                                        }))}>
                                                                    <option value={''}>Select</option>
                                                                    <option value={'true'}>Active</option>
                                                                    <option value={'false'}>Passive</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <input type={'text'} placeholder={'Address'}
                                                                       className={'form-control'}
                                                                       name={'address'}
                                                                       value={filterForm.address}
                                                                       onChange={(e) => setFilterForm((o) => ({
                                                                           ...o,
                                                                           address: e.target.value
                                                                       }))}
                                                                />
                                                            </td>
                                                            <td>
                                                                <select className={'form-control'} name={'type'} value={filterForm.type}
                                                                        onChange={(e) => setFilterForm((o) => ({
                                                                            ...o,
                                                                            type: e.target.value
                                                                        }))}>
                                                                    <option value={''}>Select</option>
                                                                    <option value={'STANDARD'}>Standard</option>
                                                                    <option value={'PREMIUM'}>Premium</option>
                                                                </select>
                                                            </td>
                                                            <td></td>
                                                            <td>
                                                                <button className={'btn btn-success'} type={"submit"}>
                                                                    <i className={'bi bi-search'}/>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                    <input type={'hidden'} name={'p'} value={pageNumber}/>
                                                </form>
                                                <table className="table table-lg">
                                                    <tbody>

                                                    {wallets.map(x => (
                                                        <tr className={x.type === 'PREMIUM' ? 'alert-light-warning color-warning' : ''}>
                                                            <td>
                                                                {x.active ?
                                                                    <span
                                                                        className="badge bg-light-success">Active</span>
                                                                    :
                                                                    <span
                                                                        className="badge bg-light-danger">Passive</span>}
                                                            </td>
                                                            <td className="text-bold-500">
                                                                <a href={'https://bscscan.com/address/' + x.address}
                                                                   target={'_blank'} rel={'noreferrer'}>
                                                                    {x.address}
                                                                </a>
                                                            </td>
                                                            <td>
                                                                {x.type === 'PREMIUM' &&
                                                                    <i className={'bi bi-star'}/>} {x.type}
                                                                {x.type === 'PREMIUM' && <>({x.points})</>}
                                                            </td>
                                                            <td className="text-bold-500">
                                                                {x.balances.map(m => (
                                                                    <span>
                                                                        <span>{m.quantity} {m.symbol}</span>
                                                                        ~
                                                                        <span>{m.usd} USDT</span>
                                                                    </span>
                                                                ))}
                                                            </td>
                                                            <td>
                                                                <Link to={'/wallets/edit/' + x.id}
                                                                      className={'btn btn-primary'}>
                                                                    Edit
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <Pagination {...page} />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer/>
            </div>
        </div>
    );

};

export default Wallets;
