import React, {useContext} from "react";
import {AuthContext} from "../services/auth-context";

const MobileMenuButton = () => {
    const {set} = useContext(AuthContext);

    const showMenu = () => {
        set('sidebar-class', 'active');
    };

    return (
        <header className="mb-3">
            <button className="burger-btn d-block d-xl-none btn btn-default" onClick={showMenu}>
                <i className="bi bi-justify fs-3"/>
            </button>
        </header>
    );
};

export default MobileMenuButton;