import React, {useEffect, useState} from "react";
import ApiService from "../../services/api-service";
import Footer from "../../component/footer";
import SideMenu from "../../component/side-menu";
import {useSearchParams} from "react-router-dom";
import MobileMenuButton from "../../component/mobile-menu-button";
import Pagination from "../../component/pagination";
import {dateFormat} from "../../services/helper";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);

const api = new ApiService();

const Payments = () => {

    const [payments, setPayments] = useState([]);

    const [page, setPage] = useState({});

    const [queryParams] = useSearchParams();
    const pageNumber = queryParams.get('p');

    useEffect(() => {
        api.payments(pageNumber)
            .then(x => {
                if (x.response) {
                    setPayments(x.response.content);
                    setPage(x.response);
                }
            })
            .catch();
    }, [pageNumber]);

    const showUserDetails = userId => {
        api.getUser(userId)
            .then(x => {
                if (x.response) {
                    let _html = '<table class="table">';
                    _html += '<tr><th>Email</th><td>' + x.response.email + '</td></tr>';
                    _html += '<tr><th>Name</th><td>' + x.response.firstName + ' ' + x.response.lastName + '</td></tr>';
                    _html += '</table>';

                    MySwal.fire({
                        position: 'center',
                        titleText: 'User Detail',
                        html: _html,
                        showCloseButton: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        backdrop: true,
                    })
                }
            }).catch();
    };

    return (
        <div id="app">

            <SideMenu active={'payments'} />

            <div id="main">
                <MobileMenuButton />

                <div className="page-heading">
                    <div className="page-title">
                        <div className="row">
                            <div className="col-12 col-md-6 order-md-1 order-last">
                                <h3>Payments ({page.totalElements})</h3>
                            </div>
                        </div>
                    </div>

                    <section className="section">
                        <div className="row" id="basic-table">
                            <div className="col-12 col-md-12">
                                <div className="card">
                                    <div className="card-content">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-lg">
                                                    <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Status</th>
                                                        <th>Amount</th>
                                                        <th>Product ID</th>
                                                        <th>User ID</th>
                                                        <th>Provider Transaction ID</th>
                                                        <th>Created At</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>

                                                    {payments.map(x => (
                                                        <tr>
                                                            <td>{x.id}</td>
                                                            <td>
                                                                {x.status === 'NEW' ? (
                                                                    <span className="badge bg-light-warning">New</span>
                                                                ) : null}
                                                                {x.status === 'APPROVED' ? (
                                                                    <span className="badge bg-light-success">Approved</span>
                                                                ) : null}
                                                                {x.status !== 'NEW' && x.status !== 'APPROVED' ? x.status : null}
                                                            </td>
                                                            <td>{x.amount} {x.currency}</td>
                                                            <td>{x.productId}</td>
                                                            <td>
                                                                <button onClick={() => showUserDetails(x.userId)} className={'btn btn-info'}>
                                                                    {x.userId}
                                                                </button>
                                                            </td>
                                                            <td>{x.providerTransactionId}</td>
                                                            <td title={x.createdAt}>
                                                                {dateFormat(x.createdAt)}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <Pagination {...page} />
                                </div>
                            </div>
                        </div>
                    </section>

                </div>

                <Footer />
            </div>
        </div>
    );

};

export default Payments;
