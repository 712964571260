import './App.css';
import {SystemContext} from "./services/auth-context";
import {BrowserRouter} from "react-router-dom";
import Router from "./router";
import HttpsRedirect from "./services/https-redirect";

function App() {
    return (
        <SystemContext>
            <HttpsRedirect>
                <BrowserRouter>
                    <Router/>
                </BrowserRouter>
            </HttpsRedirect>
        </SystemContext>
    );
}

export default App;
