import React, {useEffect, useState} from "react";
import ApiService from "../../services/api-service";
import Footer from "../../component/footer";
import SideMenu from "../../component/side-menu";
import {useSearchParams} from "react-router-dom";
import MobileMenuButton from "../../component/mobile-menu-button";
import Pagination from "../../component/pagination";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);

const api = new ApiService();

const CorrectWords = () => {

    const [words, setWords] = useState([]);

    const [page, setPage] = useState({});

    const [queryParams] = useSearchParams();
    const pageNumber = queryParams.get('p');

    const getWords = (p) => {
        api.correctWords(p)
            .then(x => {
                if (x.response) {
                    setWords(x.response.content);
                    setPage(x.response);
                }
            })
            .catch();
    };

    useEffect(() => {
        getWords(pageNumber);
    }, [pageNumber]);

    const deleteWord = (id) => {
        MySwal.fire({
            title: <p>Word will delete, are you sure?</p>,
            confirmButtonText: 'Delete',
            confirmButtonColor: '#dc3545',
            showCloseButton: true,
            showCancelButton: true,
            backdrop: true,
        }).then((result) => {
            if (result.isConfirmed) {
                api.deleteCorrectWord(id)
                    .then(x => {
                        if (x?.code === 100) {
                            getWords(pageNumber);
                        }
                    })
                    .catch();
            }
        });
    };

    return (
        <div id="app">

            <SideMenu active={'correct-words'} />

            <div id="main">
                <MobileMenuButton />

                <div className="page-heading">
                    <div className="page-title">
                        <div className="row">
                            <div className="col-12 col-md-6 order-md-1 order-last">
                                <h3>Correct Words ({page.totalElements})</h3>
                            </div>
                        </div>
                    </div>

                    <section className="section">
                        <div className="row" id="basic-table">
                            <div className="col-12 col-md-12">
                                <div className="card">
                                    <div className="card-content">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-lg">
                                                    <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Word</th>
                                                        <th>Provider</th>
                                                        <th style={{width: 100}}/>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {words.map(x => (
                                                        <tr>
                                                            <td>{x.id}</td>
                                                            <td>{x.word}</td>
                                                            <td>{x.provider}</td>
                                                            <td>
                                                                <button onClick={() => deleteWord(x.id)} className={'btn btn-danger'} style={{marginLeft: 10}}>
                                                                    Delete
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <Pagination {...page} />
                                </div>
                            </div>
                        </div>
                    </section>

                </div>

                <Footer />
            </div>
        </div>
    );

};

export default CorrectWords;
