import React from "react";
import {Link, useLocation} from "react-router-dom";

const Pagination = (props) => {
    const location = useLocation();
    const {
        first,
        last,
        number,
        // size,
        // totalElements,
        // totalPages
    } = props;

    const next = () => {
        return `${location.pathname}?p=${number + 2}`;
    };

    const prev = () => {
        return `${location.pathname}?p=${number}`;
    };

    return (
        <nav aria-label="Page navigation">
            <ul className="pagination pagination-primary justify-content-center">
                <li className={'page-item ' + (first ? 'disabled' : '')}>
                    <Link to={prev()} className="page-link">Previous</Link>
                </li>
                <li className="page-item active">
                    <a className="page-link" href="#/">{number + 1}</a>
                </li>
                {/*<li className="page-item"><a className="page-link" href="#">2</a></li>*/}
                {/*<li className="page-item"><a className="page-link" href="#">3</a></li>*/}
                <li className={'page-item ' + (last ? 'disabled' : '')}>
                    <Link to={next()} className="page-link">Next</Link>
                </li>
            </ul>
        </nav>
    );

};

export default Pagination;